<template>
    <div>
        <div class="firm-panel">
            <h3 class="g-title">雇主简介</h3>

            <div class="employer-msg-group">
                <div class="hd">
                    <span class="id">ID: {{value.geek_id}}</span>
                    <div class="msg-cont">
                        <span class="avatar">
                            <img :src="util.reImg(value.avatar || defaultAvatar)" />
                        </span>
                        <div class="msg-txt">
                            <h4 class="title">{{value.name}}</h4>
                            <p class="rate">
                                信用评分：
                                <el-rate
                                    :value="parseInt(value.credence)"
                                    :colors="colors"
                                    disabled-void-color="#D9D9D9"
                                    disabled
                                    show-score
                                    text-color="#333"
                                ></el-rate>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="bd">
                    <ul class="msg-wrap">
                        <li>
                            <span>雇主名称：</span>
                            <strong>{{value.name}}</strong>
                        </li>
                        <li>
                            <span>发布项目总数：</span>
                            <strong>{{value.task_total || 0}}个</strong>
                        </li>
                        <li>
                            <span>注册时间：</span>
                            <strong v-text="value.ctime"></strong>
                        </li>
                        <li>
                            <span>已支付项目总数：</span>
                            <strong>{{value.pay_total || 0}}个</strong>
                        </li>
                        <li>
                            <span>共计支付：</span>
                            <strong class="price">￥{{value.reward}}元</strong>
                        </li>
                        <li>
                            <span>平均支付延迟：</span>
                            <strong>{{value.delay_day || 0}}天</strong>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "index",
    props: ['value'],
    data() {
        return {
            colors: ["#39BEA3 ", "#39BEA3 ", "#39BEA3 "],
            attestation: [],
            user: []
        };
    },
    computed: {
        defaultAvatar: function () {
            return this.websiteConfigs.sourceUrl + "/upload/image/geek_avatar.png";
        }
    },
};
</script>





